import {ready} from "../utils/ready.mjs";
import {throttle} from "../utils/throttle.mjs";

;(function () {
    'use strict';

    // init main navigation
    ready(function () {
        var button = document.querySelector('.js-menu-toggle');
        if (typeof(button) != 'undefined' && button != null) {
            var nav = new MainNavigation(button, 890);
        }
    });

    // Constructor
    function MainNavigation(button, desktopNavMinWidth)
    {

        var self = this;

        this.button = button;

        // get drawer id
        if (this.button.hasAttribute('data-menu')) {
            this.navID = this.button.getAttribute('data-menu');
        }
        if (typeof(this.navID) == 'undefined' || this.navID == null) {
            throw new Error('Button has no data-menu attribute.');
        }

        // get nav
        this.nav = document.getElementById(this.navID);
        if (typeof(this.nav) == 'undefined' || this.nav == null) {
            throw new Error('Nav `#' + this.navID + '` does not exist.');
        }

        // get menu breakpoint width
        if (typeof(desktopNavMinWidth) === 'undefined' || desktopNavMinWidth == null) {
            this.desktopNavMinWidth = 890;
        } else {
            this.desktopNavMinWidth = desktopNavMinWidth;
        }
        this.isMobileView = true;

        // Add (initial) button semantics
        this.button.setAttribute('aria-haspopup', true);
        this.button.setAttribute('aria-expanded', false);
        this.button.setAttribute('role', 'button');
        this.button.setAttribute('aria-controls', this.navID);

        // Handle button click
        this.button.addEventListener('click', function (e) {
            e.preventDefault();
            self.toggle();
        }.bind(this))

        // Also toggle on key interactions
        this.button.addEventListener('keydown', function (e) {

            if (e.keyCode === 13 || e.keyCode === 32) {
                // enter or space
                e.preventDefault();
                self.toggle();
            } else if (e.keyCode === 40) {
                // down arrow
                if (this.button.getAttribute('aria-expanded') !== 'true') {
                    e.preventDefault();
                    this.open();
                } else {
                    this.nav.querySelector('a').focus()
                }
            } else if ((e.shiftKey && e.keyCode === 9)) {
                // shift+tab
                this.close(false);
            } else if (e.keyCode === 9) {
                // or tab
                if (this.button.getAttribute('aria-expanded') === 'true') {
                    e.preventDefault();
                    this.nav.querySelector('a').focus()
                }
            } else if (e.keyCode === 38) {
                // up arrow
                this.close();
            } else if (e.keyCode === 27) {
                // escape
                this.close();
            }
        }.bind(this))

        window.addEventListener('resize', throttle(function () {
            var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            if (width >= self.desktopNavMinWidth) {
                // desktop view
                self.isMobileView = false;
                this.close();
            } else {
                // mobile view
                self.isMobileView = true;
            }
        }, 200).bind(this));
        window.dispatchEvent(new Event('resize'));

        // get all submenu buttons
        this.submenuButtons = this.nav.querySelectorAll('.js-submenu-button');
        if (this.submenuButtons.length > 0) {
            Array.prototype.forEach.call(this.submenuButtons, function (submenuButton, index) {

                submenuButton.setAttribute('aria-haspopup', true);
                submenuButton.setAttribute('aria-expanded', submenuButton.parentNode.classList.contains('js-is-open'));
                submenuButton.setAttribute('role', 'button');
                submenuButton.setAttribute('aria-controls', submenuButton.getAttribute('data-submenu'));

                // Handle button click
                submenuButton.addEventListener('click', function (e) {
                    e.preventDefault();
                    this.toggleSubmenu(submenuButton);
                }.bind(this))

                // Also toggle on key interactions
                submenuButton.addEventListener('keydown', function (e) {

                    if (e.keyCode === 13 || e.keyCode === 32) {
                        // enter or space
                        e.preventDefault();
                        this.toggleSubmenu(submenuButton);
                    } else if (e.keyCode === 40) {
                        // down arrow
                        if (submenuButton.getAttribute('aria-expanded') === 'true') {
                            // jump in
                            e.preventDefault();
                            submenuButton.nextElementSibling.querySelector('a').focus()
                        } else {
                            // skip to next link
                            e.preventDefault();
                            submenuButton.parentNode.nextElementSibling.querySelector('a').focus()
                        }
                    } else if ((e.shiftKey && e.keyCode === 9)) {
                        // shift+tab
                        this.closeSubmenu(submenuButton, false);
                    } else if (e.keyCode === 9) {
                        // or tab
                        if ( // if submenu is open, jump in
                            submenuButton.getAttribute('aria-expanded') === 'true'
                        ) {
                            e.preventDefault();
                            submenuButton.nextElementSibling.querySelector('a').focus()
                        } else if ( // is there is a next menu item, go there
                            typeof submenuButton.parentNode.nextElementSibling !== "undefined"
                            && submenuButton.parentNode.nextElementSibling != null
                        ) {
                            e.preventDefault();
                            submenuButton.parentNode.nextElementSibling.querySelector('a').focus()
                        } else if ( // we are on the last item, go to menu button
                            typeof submenuButton.parentNode.parentNode.parentNode !== "undefined"
                            && submenuButton.parentNode.parentNode.parentNode != null
                            && submenuButton.parentNode.parentNode.parentNode.hasAttribute('id')
                            && typeof document.querySelector('button[data-menu="' + submenuButton.parentNode.parentNode.parentNode.getAttribute('id') + '"]') !== "undefined"
                            && document.querySelector('button[data-menu="' + submenuButton.parentNode.parentNode.parentNode.getAttribute('id') + '"]') != null
                            && document.querySelector('button[data-menu="' + submenuButton.parentNode.parentNode.parentNode.getAttribute('id') + '"]').offsetParent !== null
                        ) {
                            e.preventDefault();
                            document.querySelector('button[data-menu="' + submenuButton.parentNode.parentNode.parentNode.getAttribute('id') + '"]').focus();
                        } else { // do nothing
                            e.preventDefault();
                            console.log('do nothing');
                        }
                    } else if (e.keyCode === 38) {
                        // up arrow
                        if (submenuButton.getAttribute('aria-expanded') === 'true') {
                            this.closeSubmenu(submenuButton);
                        }
                        // jump to previuos link
                        e.preventDefault();
                        submenuButton.previousElementSibling.focus()
                    } else if (e.keyCode === 27) {
                        // escape
                        if (submenuButton.getAttribute('aria-expanded') === 'true') {
                            this.closeSubmenu(submenuButton);
                        } else {
                            this.close();
                        }
                    }
                }.bind(this))

            }.bind(this))
        }

        // Get the all top level links within the menu
        this.menuLinks = this.nav.querySelectorAll('a');
        if (this.menuLinks.length < 1) {
            throw new Error('The #' + this.navId + ' menu has no menu items');
        }

        // Handle key presses for menuItem
        Array.prototype.forEach.call(this.menuLinks, function (link, index) {

            // add keyboard event
            link.addEventListener('keydown', function (e) {

                if (self.isMobileView) { // mobile view
                    if (e.shiftKey && e.keyCode === 9) {
                        // shift+tab
                        focusNext(e, false);
                    } else if (e.keyCode === 38) {
                        // up arrow
                        focusNext(e, false);
                    } else if (e.keyCode === 40 || e.keyCode === 9) {
                        // down arrow or tab
                        focusNext(e, true);
                    } else if (e.keyCode === 27) {
                        // escape
                        if (
                            typeof e.target.parentNode.parentNode.previousElementSibling !== "undefined"
                            && e.target.parentNode.parentNode.previousElementSibling != null
                            && e.target.parentNode.parentNode.previousElementSibling.classList.contains('js-submenu-button')
                        ) {
                            this.closeSubmenu(e.target.parentNode.parentNode.previousElementSibling)
                        } else {
                            this.close();
                        }
                    }
                }
            }.bind(this))
        }.bind(this))

        var focusNext = function (event, down) {
            var link = event.target;
            var goingDown = down;

            // helper function for getting next legitimate element
            function getNextElement(link)
            {
                if (goingDown) {
                    if (
                        // check if subbutton is available
                        typeof link.nextElementSibling !== "undefined"
                        && link.nextElementSibling != null
                        && link.nextElementSibling.tagName == 'BUTTON'
                        && !isElementHidden(link.nextElementSibling)
                    ) {
                        console.log('downwards: subbutton same');
                        return link.nextElementSibling;
                    } else if (
                        // check if there is a visible next nav element
                        typeof link.parentNode.nextElementSibling !== "undefined"
                        && link.parentNode.nextElementSibling != null
                        && typeof link.parentNode.nextElementSibling.querySelector('a') !== "undefined"
                        && link.parentNode.nextElementSibling.querySelector('a') != null
                        && !isElementHidden(link.parentNode.nextElementSibling.querySelector('a'))
                    ) {
                        console.log('downwards: down');
                        return link.parentNode.nextElementSibling.querySelector('a');
                    } else if (
                        // check if subbutton is available on upper level
                        typeof link.parentNode.parentNode.previousElementSibling !== "undefined"
                        && link.parentNode.parentNode.previousElementSibling != null
                        && link.parentNode.parentNode.previousElementSibling.tagName === 'BUTTON'
                        && !isElementHidden(link.parentNode.parentNode.previousElementSibling)
                    ) {
                        console.log('downwards: subbutton upper');
                        return link.parentNode.parentNode.previousElementSibling;
                    } else if (
                        // check if close button is available
                        typeof link.parentNode.parentNode.parentNode !== "undefined"
                        && link.parentNode.parentNode.parentNode != null
                        && link.parentNode.parentNode.parentNode.hasAttribute('id')
                        && typeof document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.getAttribute('id') + '"]') !== "undefined"
                        && document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.getAttribute('id') + '"]') != null
                        && !isElementHidden(document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.getAttribute('id') + '"]'))
                    ) {
                        console.log('downwards: button');
                        return document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.getAttribute('id') + '"]');
                    } else {
                        console.log('downwards: no move');
                        return link;
                    }
                } else {
                    if (
                        // check if subbutton is available on same level
                        typeof link.parentNode.previousElementSibling !== "undefined"
                        && link.parentNode.previousElementSibling != null
                        && typeof link.parentNode.previousElementSibling.querySelector('button') !== "undefined"
                        && link.parentNode.previousElementSibling.querySelector('button') != null
                        && !isElementHidden(link.parentNode.previousElementSibling.querySelector('button'))
                    ) {
                        console.log('upwards: subbutton same');
                        return link.parentNode.previousElementSibling.querySelector('button');
                    } else if (
                        // check if there is a visible next nav element
                        typeof link.parentNode.previousElementSibling !== "undefined"
                        && link.parentNode.previousElementSibling != null
                        && typeof link.parentNode.previousElementSibling.querySelector('a') !== "undefined"
                        && link.parentNode.previousElementSibling.querySelector('a') != null
                        && !isElementHidden(link.parentNode.previousElementSibling.querySelector('a'))
                    ) {
                        console.log('upwards: up');
                        return link.parentNode.previousElementSibling.querySelector('a');
                    } else if (
                        // check if subbutton is available on upper level
                        typeof link.parentNode.parentNode.previousElementSibling !== "undefined"
                        && link.parentNode.parentNode.previousElementSibling != null
                        && link.parentNode.parentNode.previousElementSibling.tagName === 'BUTTON'
                        && !isElementHidden(link.parentNode.parentNode.previousElementSibling)
                    ) {
                        console.log('upwards: subbutton upper');
                        return link.parentNode.parentNode.previousElementSibling;
                    } else if (
                        // check if close button is available
                        typeof link.parentNode.parentNode.parentNode !== "undefined"
                        && link.parentNode.parentNode.parentNode != null
                        && link.parentNode.parentNode.parentNode.hasAttribute('id')
                        && typeof document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.getAttribute('id') + '"]') !== "undefined"
                        && document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.getAttribute('id') + '"]') != null
                        && !isElementHidden(document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.getAttribute('id') + '"]'))
                    ) {
                        console.log('upwards: button');
                        return document.querySelector('button[data-menu="' + link.parentNode.parentNode.parentNode.getAttribute('id') + '"]');
                    } else {
                        console.log('upwards: no move');
                        return link;
                    }
                }
            }

            function isElementHidden(element)
            {
                return (element.offsetParent === null);
            }

            // don't allow default movement
            event.preventDefault();

            // make move
            var nextItem = getNextElement(link);
            if (nextItem !== link) {
                nextItem.focus();
            }

        }.bind(this)

        // register outside click
        this.outsideClick = function (e) {
            if (this.button.getAttribute('aria-expanded') === 'true' && !this.nav.contains(e.target) && !this.button.contains(e.target)) {
                this.close(false);
            }
        }
        document.addEventListener('click', this.outsideClick.bind(this));

        // initiate listeners object for public events
        this._listeners = {}
    }

    // Open methods
    MainNavigation.prototype.open = function () {
        // open nav
        this.button.setAttribute('aria-expanded', true);
        // mark body
        document.documentElement.classList.add('js-menu-open');
        // fire open event
        this._fire('open');
        return this;
    }
    MainNavigation.prototype.openSubmenu = function (button) {
        // open nav
        button.setAttribute('aria-expanded', true);
        // mark item
        button.parentNode.classList.add('js-is-open');
        // fire open event
        this._fire('openSubmenu');
        return this;
    }

    // Close methods
    MainNavigation.prototype.close = function (setFocus) {
        setFocus = typeof setFocus === "undefined" ? true : setFocus;
        // close nav
        this.button.setAttribute('aria-expanded', false);
        // mark body
        document.documentElement.classList.remove('js-menu-open');
        // set focus
        if (setFocus) {
            this.button.focus();
        }
        // fix hash
        if (typeof window.location.hash !== "undefined" && window.location.hash == "#nav") {
            var href = window.location.href.split('#')[0];
            if (history.pushState) {
                history.pushState(null, null, href);
            } else {
                window.location = href;
            }
        }
        // fire close event
        this._fire('close');
        return this;
    }
    MainNavigation.prototype.closeSubmenu = function (button, setFocus) {
        setFocus = typeof setFocus === "undefined" ? true : setFocus;
        // open nav
        button.setAttribute('aria-expanded', false);
        // mark item
        button.parentNode.classList.remove('js-is-open');
        // set focus
        if (setFocus) {
            button.focus();
        }
        // fire open event
        this._fire('closeSubmenu');
        return this;
    }

    // Toggle methods
    MainNavigation.prototype.toggle = function () {
        var expanded = this.button.getAttribute('aria-expanded') === 'true';
        return expanded ? this.close() : this.open();
    }
    MainNavigation.prototype.toggleSubmenu = function (button) {
        var expanded = button.getAttribute('aria-expanded') === 'true';
        return expanded ? this.closeSubmenu(button) : this.openSubmenu(button);
    }

    // focus menu button
    MainNavigation.prototype.focusMenuButton = function () {
        this.button.focus();
        return this;
    }

    MainNavigation.prototype._fire = function (type, data) {
        if (typeof this._listeners === 'undefined') {
            this._listeners = [];
        }
        var listeners = this._listeners[type] || [];

        listeners.forEach(function (listener) {
            listener(data);
        })
    }

    MainNavigation.prototype.on = function (type, handler) {
        if (typeof this._listeners[type] === 'undefined') {
            this._listeners[type] = [];
        }

        this._listeners[type].push(handler);

        return this;
    }

    MainNavigation.prototype.off = function (type, handler) {
        var index = this._listeners[type].indexOf(handler);

        if (index > -1) {
            this._listeners[type].splice(index, 1);
        }

        return this;
    }

    // Export MainNavigation
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = MainNavigation;
    } else if (typeof define === 'function' && define.amd) {
        define('MainNavigation', [], function () {
            return MainNavigation;
        })
    } else {
        // attach to window
        window.MainNavigation = MainNavigation;
    }
}());
