import {ready} from "../utils/ready.mjs";

;(function () {
    'use strict';

    // hide page block if empty to fix spacing issue
    ready(function () {
        const tables = document.querySelectorAll('.s-cmsContent table');
        if (tables.length > 0) {
            Array.prototype.forEach.call(tables, function (table) {
                var parent = table.parentNode;
                var wrapper = document.createElement('div');
                wrapper.classList.add('scroll-table-wrapper');
                parent.insertBefore(wrapper, table);
                wrapper.appendChild(table);
            });
        }
    });

}());
