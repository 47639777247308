import {ready} from "../utils/ready.mjs";

;(function () {
    'use strict';

    // init main navigation
    ready(function () {
        var fields = document.querySelectorAll('input[type="password"]');
        if (fields.length > 0) {
            Array.prototype.forEach.call(fields, function (field) {
                var pw = new PasswordField(field);
            });
        }
    });

    // Constructor
    function PasswordField(field)
    {
        var self = this;

        this.field = field;
        this.field.parentNode.classList.add('PasswordToggle');
        this.field.classList.add('PasswordToggle__field');
        this.field.setAttribute('spellcheck', 'false');
        this.field.setAttribute('autocomplete', 'off');

        // add button
        var button = document.createElement('button');
        button.setAttribute('type', 'button');
        button.setAttribute('role', 'button');
        button.setAttribute('aria-label', 'Show password');
        button.setAttribute('aria-controls', this.field.getAttribute('id'));
        button.classList.add('PasswordToggle__button');
        this.field.parentNode.append(button);
        this.button = button;

        var message = document.createElement('span');
        message.setAttribute('aria-live', 'polite');
        message.classList.add('u-visuallyhidden', 'PasswordToggle__message');
        this.field.parentNode.append(message);
        this.message = message;

        // Handle button click
        this.button.addEventListener('click', function () {
            this.toggle();
        }.bind(this));

        // handle form submit
        this.field.closest('form').addEventListener('submit', function() {
            this.close();
        }.bind(this));

        // initiate listeners object for public events
        this._listeners = {}
    }

    // Open methods
    PasswordField.prototype.open = function () {
        // update field
        this.field.classList.add('js-password-visible');
        this.field.setAttribute('type', 'text');
        // update button
        this.button.setAttribute('aria-label', 'Hide password');
        // update message
        this.message.innerText = 'Your password is shown';
        // fire open event
        this._fire('open');
        return this;
    }

    // Close methods
    PasswordField.prototype.close = function () {
        // update field
        this.field.classList.remove('js-password-visible');
        this.field.setAttribute('type', 'password');
        // update button
        this.button.setAttribute('aria-label', 'Show password');
        // update message
        this.message.innerText = 'Your password is hidden';
        // fire close event
        this._fire('close');
        return this;
    }

    // Toggle methods
    PasswordField.prototype.toggle = function () {
        var visible = this.field.classList.contains('js-password-visible');
        return visible ? this.close() : this.open();
    }

    PasswordField.prototype._fire = function (type, data) {
        if (typeof this._listeners === 'undefined') {
            this._listeners = [];
        }
        var listeners = this._listeners[type] || [];
        listeners.forEach(function (listener) {
            listener(data);
        })
    }

    PasswordField.prototype.on = function (type, handler) {
        if (typeof this._listeners[type] === 'undefined') {
            this._listeners[type] = [];
        }
        this._listeners[type].push(handler);
        return this;
    }

    PasswordField.prototype.off = function (type, handler) {
        var index = this._listeners[type].indexOf(handler);
        if (index > -1) {
            this._listeners[type].splice(index, 1);
        }
        return this;
    }

    // Export PasswordField
    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = PasswordField;
    } else if (typeof define === 'function' && define.amd) {
        define('PasswordField', [], function () {
            return PasswordField;
        })
    } else {
        // attach to window
        window.PasswordField = PasswordField;
    }
}());
