import {ready} from "../utils/ready.mjs";
import {throttle} from "../utils/throttle.mjs";
import {supportsPassiveEvents} from "../utils/supportsPassiveEvents.mjs";

;(function () {
	'use strict';

	ready(function() {

        var previousY = 0
        var currentY = 0

        var scrollcheck = function() {
            currentY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
            if (currentY > 100) {
                document.body.classList.add("scrolled");
            } else {
                document.body.classList.remove("scrolled");
            }
            if (currentY > previousY) {
                document.body.classList.add("scrolling-down");
                document.body.classList.remove("scrolling-up");
            } else {
                document.body.classList.add("scrolling-up");
                document.body.classList.remove("scrolling-down");
            }
            previousY = currentY;
        }

        // only enable on modern browsers
        if (supportsPassiveEvents()) {
            window.addEventListener(
                'scroll',
                throttle(scrollcheck, 200),
                {passive: true}
            );
            scrollcheck();
        }
	});
}());
